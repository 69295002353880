.App * {
  font-family: "Exo 2", sans-serif;
  box-sizing: border-box;
  overflow-y: hidden;
  padding: 0px;
  margin: 0px;
}

.ui {
  font-family: "Exo 2"!important;
}

#app {
  width: 100%;
  height: 100%;
}

html {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}



.Toastify__toast.Toastify__toast--error {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  background: white;
  color: #444;
  font-size: 16px;
  height: 130px;
}

@media screen and (min-width: 1370px) {
  .correct-resolution {
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .wrong-resolution {
    display: none;
  }
}

#vrsn {
  position: fixed;
  bottom: 5px;
  right: 5px;
  color: #444;
  z-index: 99;
  font-size: 18px;
}

#stage {
  position: fixed;
  bottom: 5px;
  left: 5px;
  font-size: 18px;
  color: #444;
}

.slot {
  border: solid 0.1px #444;
  border-radius: 10px;
  padding: 10px;
  background-color: #D0D0D0;
  height: 90px;
  width: 100%;
  display: flex;
  vertical-align: middle !important;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.module {
  margin: auto !important;
  border: solid 0.1px #444;
  border-radius: 10px;
  background-color: #444;
}

.one-slot-sp-icon {
  height: 70px;
  width: auto;
  margin: auto
}

.foure-slot-sp-icon {
  height: auto;
  width: 50%;
  margin: auto
}

.slot-icon {
  height: auto;
  width: 100%;
  justify-self: center !important;
  vertical-align: middle !important;

}


.shadow-button {
  -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75) !important;
  -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75) !important;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75) !important;
}


.text-inside-battery-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #444;
  font-size: 20px
}